<template>
<div>
  <h2>Test page</h2>
  <Spinner v-if="this.loading" />
</div>
</template>

<script>
import Api from '@/utils/api'
import Spinner from '@/components/Spinner'

export default {
  name: 'Test',
  data() {
    return {
      posts: [],
      loading: true
    }
  },
  mounted() {
    this.loading = true
    Api.get("post").then((r) => { if (!r) { return }; ; console.log(r) })
    this.loading = false
  },
  components: {
    Spinner
  }
}
</script>
